@import "~rivals/styles/colors";

// Header
$header-border-height: 4px;
$header-height-desktop: 76px;
$header-height-total: $header-height-desktop + $header-border-height;
$cta-banner-height: 47px;
$new-article-md-desktop-min: 1205px;
$new-article-md-desktop-max: 1310px;

// Z-indices
$ant-carousel-slick-dots-z-index: 15;
$header-z-index: 1000;
$player-block-wrapper-z-index: 3;
$slider-arrows-z-index: 2;
$slider-scroll-shadow-indicator-z-index: 1;
$lightbox-z-index: 1001;
$embed-athlete-modal-z-index: 1002;
$transfer-filter-z-index: 3;
$distro-scale-z-index: 99;

// Border radius
$border-radius-4: 4px;
$border-radius-8: 8px;
$border-radius-16: 16px;
$border-radius-28: 28px;
$border-radius-100: 100px;

// Borders
$border-85: 1px solid $gray85;
$clay-border: 1px solid $clay;

// Rails (left and right)
$rail-width: 300px;

// Body (articles)
$body-width: 700px;
$mobile-body-width: 336px;

//Homepage
$homepage-main-content-width: 700px;
$carousel-arrows-height: 48px;
$dashboard-tablet-widget-width: 630px;
$dashboard-widget-width: 300px;
$dashboard-widget-height: 341px;
$ticker-dropdown-height: 40px; // EventTicker aka Top Events title

// Input
$input-height: 40px;

// Sign up
$sign-up-container-width: 624px;

//Registration
$registration-container-width-max: 960px;

// Graphs
$graph-width: 700px;

// Spacing
$spacing-2: 2px;
$spacing-4: 4px;
$spacing-6: 6px;
$spacing-8: 8px;
$spacing-10: 10px;
$spacing-12: 12px;
$spacing-16: 16px;
$spacing-24: 24px;
$spacing-26: 26px;
$spacing-32: 32px;
$spacing-36: 36px;
$spacing-40: 40px;
$spacing-48: 48px;
$spacing-64: 64px;

// Relative spacing
$spacing-xxs: 2;
$spacing-xs: 4;
$spacing-s: 6;
$spacing-m: 8;
$spacing-l: 12;
$spacing-xl: 16;
$spacing-xxl: 24;

// Modals
$ANTD_MODAL_DEFAULT_WIDTH: 520px;

// Profile Image
$profile-img-length: 200px;
$profile-img-mobile-length: 142px;

// Misc
$galaxy-fold-width: 280px; // Not an official breakpoint
$career-highlight-branch-height: 32px;
$career-highlight-timeline-height: 298px;

// Spotlight Video
$spotlight-video-aspect-ratio: 16 / 9;
$spotlight-video-lg-width: 410px;
$spotlight-video-lg-height: $spotlight-video-lg-width /
  $spotlight-video-aspect-ratio;
$spotlight-video-sm-width: $galaxy-fold-width - 2 * $spacing-12;
$spotlight-video-sm-height: $spotlight-video-sm-width /
  $spotlight-video-aspect-ratio;

$desktop-bar-height: 80px;
$mobile-bar-height: 54;
// Profile Scroll Anchor
// This is calculated from the height of the header + profile bar + extra
$scroll-anchor-desktop: -($header-height-total + $desktop-bar-height + 4px);
$scroll-anchor-mobile: -($header-height-total + $mobile-bar-height + 4px);

// latest activity widget:
$latest-activity-card-height: 182px;
$latest-activity-card-header-height: 56px;

// College Social Card:
$college-social-card-height: 630px;
$college-social-card-width: 1200px;
$college-social-card-header-height: 42px;
$college-social-card-internal-padding: $spacing-48;

// Athlete Card
$athlete-card-height: 166px;
$athlete-card-height-mobile: 246px;
$athlete-card-width: 630px;

// Athlete Embed Card (for iframe usage):
$athlete-embed-card-height: 170px;
$athlete-embed-card-min: 599px;
$athlete-embed-card-width: 340px;

// Typography
$font-size-default: 14;
$line-height-default: 1.5715;

@mixin set-horizontal-padding($spacing) {
  padding-left: $spacing;
  padding-right: $spacing;
}

@mixin set-vertical-padding($spacing) {
  padding-bottom: $spacing;
  padding-top: $spacing;
}
